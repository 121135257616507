@use "theme/variables" as *;

.statistics-group {
    display: flex;
    gap: $spacer-025;
    width: 100%;

    &.horizontal {
        flex-direction: row;

        .amount {
            min-width: $spacer-7;
        }

        .c-amount {
            font-size: $font-xs;
        }

        .stat {
            cursor: pointer;
        }
    }

    &.vertical {
        flex-direction: column;

        .amount {
            min-width: $spacer-7 * 3;
        }
    }

    .stat {
        align-items: center;
        display: flex;
        gap: $spacer-1;
        width: 100%;

        .amount {
            align-items: center;
            display: flex;
            font-size: $font-xl;
            font-weight: 600;
            gap: $spacer-2;
            padding: $spacer-2;
        }

        .text {
            flex: 1;
            font-weight: 500;
        }

        &.danger {
            background-color: $danger-5;
            color: $danger-2;

            .amount {
                background: $danger-4;
            }
        }

        &.default {
            background-color: $surface-5;
            color: $surface-2;

            .amount {
                background: $surface-4;
            }
        }

        &.info {
            background-color: $info-5;
            color: $info-2;

            .amount {
                background: $info-4;
            }
        }

        &.success {
            background-color: $success-5;
            color: $success-2;

            .amount {
                background: $success-4;
            }
        }

        &.warning {
            background-color: $warning-5;
            color: $warning-2;


            .amount {
                background: $warning-4;
            }
        }
    }
}
