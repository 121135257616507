@use "theme/variables" as *;

.app {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: var(--app-height);
    width: 100%;

    .l-main-col {
        display: flex;
        flex-direction: column;
        position: relative; // float-panel attaches
        width: 100%;

        .c-bar-main {
            height: $layout-header-height;
            max-height: $layout-header-height;
            transform: scaleY(1);
            transition: transform 0.3s ease-in-out, max-height 0.3s ease-out;
        }

        .view-header {
            align-content: space-between;
            align-items: flex-start;
            display: flex;
            flex-direction: column;

            .view-actions {
                margin-bottom: var(--query-spacer);
            }
        }

        .view-fw {
            overflow-y: scroll;
            padding: var(--query-spacer);
        }

        > .view {
            overflow-y: scroll;
            padding: var(--query-spacer);
        }

        .view-container {
            align-items: stretch;
            display: flex;
            height: calc(var(--app-height) - var(--header-size));
            padding-left: var(--query-spacer);
            padding-top: var(--query-spacer);
            position: relative;
            width: 100%;

            &.context-create {

                &::before {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .float-panel:not(.float-panel--active) + .view {
                // View next to an inactive float-panel (e.g. filters)
                padding-left: var(--query-spacer);
            }

            .view {
                flex: 1;
                overflow-y: scroll;
                padding-right: var(--query-spacer);
                width: 100%;


                &.scroll-table {
                    padding-top: 0;
                }

                > h3 {
                    color: $grey-2;
                }

                .text-container {
                    max-width: 700px;
                }
            }
        }

        &.header-collapsed {

            .c-bar-main {
                max-height: 0;
                transform: scaleY(0);
            }

            > .view-container {
                max-height: var(--app-height);
            }

            .view-fw {
                height: var(--app-height);
            }
        }
    }
}

html.desktop {

    .l-main-col {

        .view-container {
            position: relative;
        }
    }
}

html.tablet {

    .l-main-col {

        .view-container {
            position: relative;

            &.minimizable-context-panel {

                .view {
                    // Reserve space at the right, where the absolute positioned panel-right is on top.
                    // This only needs to be done for a panel-context with a minimized view option.
                    margin-right: calc(#{$panel-collapsed-width});

                    // the scrollbar must be next to panel-context; that's why the padding is
                    // not applied to its parent .view-container.
                    padding-right: var(--query-spacer);
                }
            }


            .c-panel-context:not(.float-panel--active) {
                // The right panel becomes absolutely positioned in the view-container
                // in order to save horizontal space. These rules must not apply when
                // this panel becomes an active float-panel, which locates the panel-context
                // to the left of the screen.
                height: calc(var(--app-height) - var(--header-size) - var(--query-spacer));
                position: absolute;
                right: 0;
                top: var(--query-spacer);
            }
        }
    }
}

html.mobile {

    .l-main-col {

        .view-container {
            // Allow float-panels to be placed relative to the app container,
            // instead of the view-container. The panel-right has become a float-panel
            // anyway.
            position: unset;
        }

        .view-container.minimizable-context-panel {

            .view {
                // The absolutely positioned panel-context from $breakpoint-40 became a float-panel;
                // undo the extra margin at the right.
                margin-right: 0;
            }
        }
    }
}
