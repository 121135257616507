@use "theme/variables" as *;

.memo-section {

    .memo-header {
        align-items: center;
        border-top: 1px solid;
        box-shadow: 0 -4px 4px rgb(0 0 0 / 5%);
        color: $grey-2;
        display: flex;
        flex-direction: column;
        font-size: $font-d;
        font-weight: 600;
        gap: $spacer-05;
        padding: $spacer-1;

        .list-item {
            display: flex;
            font-weight: 600;
            gap: $spacer-05;

            span {
                display: flex;
                gap: $spacer-05;
            }
        }
    }

    .memo-content {
        background: $white;
        font-size: $font-s;
        line-height: $font-xl;
        min-height: $spacer-6;
        white-space: pre-line;

        textarea {
            background: $white;
            border: 1px solid $surface-5;
            border-top: 0;
            height: 100%;
            min-height: 136px;
            overflow-y: auto;
            padding: $spacer-1;
            resize: vertical;
        }
    }

    &.default {

        .memo-header {
            background: $surface-6;
            border-color: $surface-4;
        }
    }

    &.warning {

        .memo-header {
            background: $warning-6;
            border-color: $warning-4;
            color: $warning-2;
        }
    }
}

.c-panel-context {

    .memo-section {

        .memo-header {
            background: $surface-6;
            border-color: $surface-4;
            margin-top: $spacer-1;
        }
    }

    .memo-content {
        padding: $spacer-1;
    }
}
