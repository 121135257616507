@use "theme/variables" as *;

.c-link-group {
    align-items: center;
    display: flex;
    gap: $spacer-05;

    .account-slug-icon {
        height: $spacer-2;
        margin: 0;
        width: $spacer-2;
    }
}
