@use "theme/variables" as *;
@use "common/lib/mixins" as *;

.break-line {
    align-items: center;
    background: $info-5;
    border-bottom: 1px solid $default-4;
    color: $info-1;
    display: flex;
    font-weight: 600;
    gap: $spacer-1;
    padding: $spacer-1 $spacer-1 * 1.5;
}

.product-line {
    align-items: center;
    color: $grey-1;
    display: flex;
    gap: $spacer-05;
    padding: $spacer-1;
    position: relative;
    transition: all 0.3s ease;

    &:nth-of-type(odd) {
        background: $grey-6;
    }

    .quantity-type-icon {
        display: flex;
        margin-left: $spacer-1;
    }

    .quantity {
        flex: 1;
        font-style: normal;
        font-weight: 600;
        text-align: center;
    }

    .product-name {
        flex: 20;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .group {
            display: flex;
            flex-direction: column;
            gap: $spacer-05;

            .name {
                font-weight: 600;
            }

            .specs {
                font-size: $font-xs;
            }
        }
    }

    .costs {
        display: flex;
        flex: 2;
        font-weight: 600;
        justify-content: flex-end;
        margin-right: $spacer-1 * 1.25;
        text-align: right;
    }

    .actions {
        align-items: center;
        background: linear-gradient(90deg, transparent, $default-4);
        display: flex;
        gap: $spacer-05;
        height: 100%;
        justify-content: flex-end;
        opacity: 0;
        padding: $spacer-05;
        padding: $spacer-1;
        position: absolute;
        right: 0;
        visibility: hidden;
        width: 50%;
        z-index: 10;

        .c-button {
            // TODO: Replace with generic sized button
            @include square($spacer-1 * 3.5);

            border-radius: 50%;
            padding: $spacer-1 * 0.25;

            svg {
                @include square($spacer-1 * 2.5);
            }
        }
    }

    &.minimum-quantity,
    &.less-available {

        .quantity {
            color: $warning-3;
        }
    }

    &.unavailable {

        .quantity {
            color: $grey-4;
        }
    }

    &.cart-item-changed {
        background: $info-2;
        color: $default-5;

        &.warning {
            background: $warning-3;
            color: $warning-5;

            .product-warning-icon {
                color: $warning-4;
            }
        }

        &.error {
            background: $danger-3;
            color: $danger-5;

            .product-warning-icon {
                color: $danger-4;
            }
        }

        .quantity-type-icon {
            color: $info-4;
        }
    }

    &:hover {

        .actions {
            opacity: 1;
            visibility: visible;
        }
    }
}
