@use "theme/variables" as *;

input[type="time"],
input[type="date"] {
    background: $input-bg;
    border: 1px solid $input-border-color;
    height: $input-height;
    padding: $spacer-1;

    &:placeholder {
        color: $input-placeholder-color;
    }

    &:focus {
        outline: $focus-outline;
    }
}

input[type="number"] {
    background: $input-bg;
    border: 1px solid $input-border-color;
    height: $input-height;
    padding: $spacer-1;

    &:placeholder {
        color: $input-placeholder-color;
    }

    &:focus {
        outline: $focus-outline;
    }

    &[disabled] {
        background: $input-disabled-bg;
        cursor: not-allowed;
    }
}

input[type="password"],
input[type="text"] {
    background: $input-bg;
    border: 1px solid $input-border-color;
    color: $input-color;
    height: $input-height;
    padding: $spacer-1;

    // Needed for outline at the right with an additional button
    position: relative;

    &:placeholder {
        color: $input-placeholder-color;
    }

    &:focus {
        outline: $focus-outline;
    }

    &[disabled] {
        background: $input-disabled-bg;
        cursor: not-allowed;
    }
}

input[type="file"] {
    background: $input-bg;
    font-style: italic;
    padding: $spacer-1;

    &:focus {
        outline: $focus-outline;
    }

    &[disabled] {
        background: $input-disabled-bg;
        cursor: not-allowed;
    }
}

select {
    background: $input-bg;
    border: 1px solid $input-border-color;
    color: $input-color;
    height: $input-height;
    padding: $spacer-1;

    &::placeholder {
        color: $input-placeholder-color;
    }

    &[disabled] {
        background: $input-disabled-bg;
        cursor: not-allowed;
    }

    &:focus {
        outline: $focus-outline;
    }
}

textarea {
    border: 1px solid $input-border-color;
    padding: $spacer-1;
    resize: none;

    &:focus {
        outline: $focus-outline;
    }
}

.leaflet-popup-content {
    font-size: $font-l;
}

.cell-actions {
    display: flex;
    flex: 1;
    gap: $spacer-05;
    justify-content: flex-end;
}

.submit-feedback {
    background: hsl(var(--surface-h) var(--s-1) var(--l-5));
    border: 1px solid hsl(var(--surface-h) var(--s-1) var(--l-4));
    bottom: $spacer-4;
    box-shadow: $shadow-down-d;
    opacity: 0;
    padding: $spacer-1;
    position: absolute;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    visibility: hidden;

    .description {
        color: hsl(var(--info-h) var(--s-3) var(--l-2));
        font-weight: 600;

        &.warning {
            color: hsl(var(--warning-h) var(--s-3) var(--l-1));
        }

        &.success {
            color: hsl(var(--success-h) var(--s-3) var(--l-1));
        }
    }

    .invalid-field {
        display: flex;
        padding: $spacer-025 0;

        .field-name {
            flex: 1;
            font-weight: 600;
            padding-right: $spacer-2;
        }

        .message {
            color: hsl(var(--warning-h) var(--s-3) var(--l-2));
        }
    }
}

.c-submit {
    position: relative;

    button:hover {

        + .submit-feedback {
            opacity: 1;
            visibility: visible;
        }
    }
}

.btn-submit {
    max-width: 400px;
    width: 100%;
}

.flex-form {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;

    &.full-width {
        max-width: unset;
    }
}

.fieldset-group {
    display: flex;
    flex-wrap: wrap;
    gap: $spacer-4;
    margin-bottom: $spacer-6;
    max-width: 1200px;

    &:last-child {
        margin-bottom: $spacer-2;
    }

    &.large {
        max-width: 2000px;
    }

    .fieldset {
        flex: 1;
        margin: 0;

        &.fieldset-small {
            max-width: 435px;
        }
    }
}

.context-well {
    background: $info-5;
    border-bottom: 2px solid $info-3;
    padding: $spacer-05 $spacer-1;
    width: 100%;

    .field-group {

        .field {

            label {
                color: $info-2;
            }
        }
    }
}

.field-group {
    // Container for placing multiple fields next to each other.
    display: flex;
    gap: $spacer-2;
    margin-bottom: $input-margin;

    .c-field-time {
        max-width: unset;
    }

    &.vertical {
        flex-direction: column;
        gap: $spacer-1;

        .field {
            margin-bottom: 0;
        }
    }

    .field {
        flex: 1;
        margin-bottom: 0;
    }

    &.flex-end {
        align-items: flex-end;
    }

    &.no-fill {

        .field {
            flex: unset;
        }
    }

    @media (max-width: $breakpoint-10) {
        flex-direction: column;
    }
}

.field-merge {
    display: flex;
    gap: 0;

    .c-button {
        border-radius: 0 !important;
    }

    .field:first-child {

        input,
        select {
            border-right: 0;
        }
    }
}

.field-readonly-list {
    background: $default-6;
    border: 1px solid $default-5;

    // This is a condensed list; used by portal registration overview
    display: flex;
    flex-direction: column;
    padding: $spacer-1;

    .field-readonly {
        background: $grey-5;
        flex-direction: row;
        justify-content: space-between;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.fieldset {
    background: $surface-6;
    border: 1px solid $surface-5;
    margin-bottom: $spacer-2;
    max-width: 600px;
    padding: $spacer-2;
    position: relative;

    &.panel-body,
    &.large {
        max-width: 1200px;
    }

    &.largest {
        max-width: 1600px;
    }

    &.full-width {
        max-width: 100%;
    }

    &.panel-body-min-width {
        min-width: 750px;
    }

    .fieldset-label {
        // Identifies a fieldset
        border-bottom: 1px solid $surface-4;
        color: $info-2;
        font-size: $font-xl;
        font-weight: 600;
        margin-bottom: $spacer-3;
        padding-bottom: $spacer-05;
        text-transform: uppercase;
        top: -20px;
    }

    .fieldset-section {
        // A separate section within a fieldset
        background: hsl(var(--info-h) var(--s-2) var(--l-4));
        border-bottom: 1px solid hsl(var(--info-h) var(--s-2) var(--l-3));
        color: hsl(var(--info-h) var(--s-3) var(--l-1));
        font-size: $font-xl;
        font-style: italic;
        font-weight: 600;
        margin-bottom: $spacer-3;
        margin-top: $spacer-1;
        padding: $spacer-1;
    }


    .field {
        flex-basis: 0;
        flex-grow: 1;
    }
}

.c-filter-group {
    // This is an in-between solution until the filters
    // are being applied as panel in Discover.
    align-items: center;
    display: flex;
    gap: $spacer-2;
    margin-bottom: $spacer-2;
    position: relative;

    &.vertical {
        flex-direction: column;
        gap: $input-margin;
    }

    .field {
        margin-bottom: 0;
    }

    input[type="date"] {
        max-width: 150px;
    }

    .checkbox {
        align-items: center;
        display: flex;
        margin: 0;

        label {
            color: $grey-1;
        }
    }

    .btn-group {
        display: flex;
        margin-right: 0;
    }

    .filter-block {
        // Used to segment groups of filters that should wrap together.
        display: flex;
        gap: $spacer-1;
    }

    .filter-field {
        align-items: center;
        display: flex;
        margin-right: $spacer-1;

        .field {
            margin-bottom: 0;
        }

        > label {
            font-weight: 600;
            height: $input-label-height;
            margin: 0;
            margin-bottom: 2px;
            margin-right: $spacer-05;
            white-space: nowrap;
        }

        &.vertical {
            align-items: flex-start;
            flex-direction: column;
        }
    }

    .filter-group-item {

        .field {
            margin-bottom: 0;
        }

        .checkbox {
            margin: 0;
        }

        > div {
            align-items: center;
            display: flex;
            height: 100%;
        }
    }

    .form-control,
    .input-group {
        max-width: 550px;
    }

    select {
        width: auto;
    }

    .c-search-input,
    .c-search-bar {
        width: 100%;
    }

    .c-btn-group-end {
        display: flex;
        flex-flow: row-reverse;
        flex-grow: 1;
    }
}

.field-readonly-group {
    display: flex;
    gap: $spacer-2;

    .value {
        display: flex;
    }
}

.c-panel-context {

    .c-field-composed-money {
        max-width: unset;
    }
}

